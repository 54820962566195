const IS_DOCKER = process.env.NEXT_PUBLIC_DOCKER === "true"

const IS_STAGING = process.env.NEXT_PUBLIC_AWS_ENV === "preview"

const IS_DEV =
    process.env.NODE_ENV === "development" || process.env.NEXT_PUBLIC_AWS_ENV === "development"

const IS_TEST = process.env.NODE_ENV === "test" || process.env.NEXT_PUBLIC_AWS_ENV === "test"

const IS_PRODUCTION =
    process.env.NODE_ENV === "production" && process.env.NEXT_PUBLIC_AWS_ENV === "production"

const IS_TBD =
    process.env.NEXT_PUBLIC_BASE_URL &&
    !process.env.NEXT_PUBLIC_BASE_URL.includes("staging") &&
    !IS_PRODUCTION

// Duplicated from utils/string_util.ts to prevent dependency on other files
const capitalizeFirstLetter = (str?: string) =>
    str ? str.charAt(0).toUpperCase() + str.slice(1) : str

const _c = {
    // ENV
    isDev: !!IS_DEV,
    isTest: !!IS_TEST,
    isDevOrTest: !!(IS_DEV || IS_TEST),
    isStaging: !!IS_STAGING,
    isProduction: !!IS_PRODUCTION,
    isNotProduction: !IS_PRODUCTION,
    isTbd: IS_TBD,
    isDevOrTbd: !!(IS_DEV || IS_TBD),
    isDevOrStaging: !!(IS_DEV || IS_STAGING),
    isProductionOrStaging: !!(IS_PRODUCTION || IS_STAGING),
    environmentLabel: IS_PRODUCTION ? "prod" : "staging",

    isDocker: !!IS_DOCKER,

    // URLs
    BASE_URL_CANONICAL: process.env.NEXT_PUBLIC_BASE_URL,
    BASE_URL: process.env.NEXT_PUBLIC_BASE_URL ?? "http://localhost:3000",
    IS_EKS: process.env.LOGGING_ENV === "eks",
    OG_IMAGE_URL: "https://livelink-og-image.vercel.app/api/image.jpg",
    DEMO_VIDEO_100ms:
        "https://livelink-files.s3.eu-west-1.amazonaws.com/files/100ms_demo_video.mp4",
    NGROK_TUNNEL_URL: process.env.NGROK_TUNNEL_URL,
    WEBHOOK_BASE_URL: (!IS_DEV ? process.env.NEXT_PUBLIC_BASE_URL : process.env.NGROK_TUNNEL_URL)!,

    // FLAGS
    DANGEROUS_GLOBAL_DO_NOT_INDEX: !IS_PRODUCTION,

    // AWS
    isAWS: !!process.env.NEXT_PUBLIC_AWS_ENV,
    AWS_ENV: capitalizeFirstLetter(process.env.NEXT_PUBLIC_AWS_ENV),

    // DEFAULTS
    DEFAULT_STARTING_FEE: 0.15,
    DEFAULT_STARTING_STRIPE_FEE_COVERING: true,

    // MISC
    MAX_LENGTH_META_DESC: 150,
    VERSION: process.env.NEXT_PUBLIC_GIT_COMMIT_SHA?.substring(0, 7),

    // STRIPE
    STRIPE_VERIFY_STATUS_UNVERIFIED: "unverified",
    STRIPE_VERIFY_STATUS_PENDING: "pending",
    STRIPE_VERIFY_STATUS_VERIFIED: "verified",
    STRIPE_ANNUAL_PLAN_CANCEL_COUPON: IS_PRODUCTION ? "fBhZZv56" : "jxfLBtvi",
    STRIPE_MONTHLY_PLAN_CANCEL_COUPON: IS_PRODUCTION ? "AD8BaIXg" : "wCongUSV",

    // LIVELINK API
    LIVELINK_API_KEY_HEADER: "x-livelink-api-key",
    LIVELINK_API_KEY_VALUE: process.env.LIVELINK_API_KEY,

    LOCAL_STORAGE_KEYS: {
        TEMP_VIDEO_DATA: "temp-video-data",
        YOUTUBE_CHANNEL_ACTIVE_SLUG: "youtube-channel-active-slug",
        DASHBOARD_DATA_MODAL_SHOWN: "dashboard-data-modal",
        FIRST_DATE_KEY: "first-touch-date",
        FIREBASE_USER_AUTH_ID: "fauid",
        FIRST_ATTRIBUTION_KEY: "first-touch",
        RECENT_ATTRIBUTION_KEY: "recent-touch",
        RECENT_DATE_KEY: "recent-touch-date",
        INITIATED_PRICING_PLAN: "initiated-pricing-plan",
        SCHEDULE_CLIP_EXPORT: "schedule-clip-export",
        GCLID: "gclid",
        EMAIL_VERIFY_KEY: "email-verify",
    },

    COOKIE_STORAGE_UNSET: "unset",
    COOKIE_STORAGE_KEYS: {
        GCLID: "livelink.gclid",
        REFERRER: "fref",
        PAGEHREF: "pagehref",
        INITIAL_PAGE: "ipage",
    },

    PRODUCT_TESTS: {
        PLATFORM_SUBSCRIPTIONS: "ll-pt-platform-subs",
        SNIPPET_GENERATOR: "ll-pt-snippet-generator",
    },

    PRODUCT_TEST_METHODS: {
        CALL: "call",
    },

    APPLICATION_PORT: process.env.PORT ? parseInt(process.env.PORT, 10) : 3000,

    REDIS_CONNECTION: {
        host: process.env.REDIS_HOST ?? "localhost",
        port: process.env.REDIS_PORT ? parseInt(process.env.REDIS_PORT) : 6379,
    },

    // BULLMQ
    BULLMQ_SHORT_TASK_QUEUE: "short-tasks-queue",
    BULLMQ_SNIPPET_TASK_QUEUE: "snippet-tasks-queue",
    BULLMQ_EXPORT_TASK_QUEUE: "export-tasks-queue",
    BULLMQ_LONG_TASK_TIMEOUT: 1000 * 60 * 60, // 1 hour

    // SAMPLE PROJECT
    SAMPLE_TASK_ID: "0018a641-40ab-4076-b986-3240c1370901",

    // AI CLIPS
    REQUIRED_TIMELINE_PRECISION: 6,
    INTERNAL_TIMELINE_TICK_SIZE_MS: 10, // 10ms
    SIGNIFICANT_DURATION_THRESHOLD: 10 / 1_000, // 0.001s

    DEFAULT_TOTAL_MINUTES: 30,
    DEFAULT_TOTAL_STORAGE: BigInt(1024 * 1024 * 1024 * 1),
    SNIPPET_LOWER_DURATION_LIMIT: 60 * 5, // 5 minutes
    SNIPPET_UPPER_DURATION_LIMIT_FREE: 60 * 30,
    SNIPPET_UPPER_DURATION_LIMIT_STARTER: 60 * 45,
    SNIPPET_UPPER_DURATION_LIMIT_PRO: 60 * 60 * 2,
    SNIPPET_VIDEO_SIZE_LIMIT_FREE: 1024 * 1024 * 1024 * 1,
    SNIPPET_VIDEO_SIZE_LIMIT_STARTER: 1024 * 1024 * 1024 * 2,
    SNIPPET_VIDEO_SIZE_LIMIT_PRO: 1024 * 1024 * 1024 * 5,
    SNIPPET_BRANDS_COUNT_LIMIT_FREE: 1,
    SNIPPET_BRANDS_COUNT_LIMIT_STARTER: 5,

    YOUTUBE_CHANNEL_LIMIT_FOR_FREE: 2,
    YOUTUBE_CHANNEL_REFRESH_LIMIT_HOURS: 2,
    YOUTUBE_CHANNEL_DEFAULT_PAGE_SIZE: 6,

    // SUBSCRIPTIONS
    CURRENT_SUBSCRIPTION_VERSION: "6",
    SHORT_TRIAL_SUBSCRIPTION_VERSION: "3",
    NO_TRIAL_SUBSCRIPTION_VERSION: "4",
    SUBSCRIPTION_CANCELLATION_FEE: {
        amountInLowestDenom: 2500,
        currency: "usd",
    },

    VIDEO_STORYBOARDS: {
        THUMBNAIL_WIDTH: 160,
        THUMBNAIL_HEIGHT: 90,
        COLUMNS_PER_FILE: 5,
        ROWS_PER_FILE: 5,
        THUMBNAILS_PER_FILE: 25,
    },

    TASK_FILES: {
        FACE_DETECTIONS_FILE: "face_detections.json",
        FACE_CHANGES_FILE: "face_change_times.json",
        TRANSCRIPT_FILE: "transcript.json",
    },
}

export const getCurrentEnv = (): "production" | "dev" | "test" | "staging" => {
    if (_c.isDev) {
        return "dev"
    }
    if (_c.isTest) {
        return "test"
    }
    if (_c.isStaging) {
        return "staging"
    }
    if (_c.isProduction) {
        return "production"
    }
    return "dev"
}

export default _c
