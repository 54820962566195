import routes from "../configs/routes"
import {
    IYoutubeChannelAddBody,
    IYoutubeChannelAddResponse,
} from "../pages/api/v1/youtube-channel/add"
import { TYoutubeChannelFollowerResponse } from "../pages/api/v1/youtube-channel/followers"
import {
    IYoutubeChannelLoadVideosQuery,
    TYoutubeChannelLoadVideosResponse,
} from "../pages/api/v1/youtube-channel/load-videos"
import { IYoutubeChannelRefreshResponse } from "../pages/api/v1/youtube-channel/refresh"
import {
    IYoutubeChannelRemoveBody,
    IYoutubeChannelRemoveResponse,
} from "../pages/api/v1/youtube-channel/remove"
import { handleRequest } from "../utils/network_util"

const youtubeService = (hr = handleRequest) => ({
    addYoutubeChannel: (body: IYoutubeChannelAddBody): Promise<IYoutubeChannelAddResponse> =>
        hr(routes.POST_ADD_YOUTUBE_CHANNEL, body),
    refreshYoutubeChannel: (slug: string): Promise<IYoutubeChannelRefreshResponse> =>
        hr(routes.GET_YOUTUBE_CHANNEL_REFRESH_VIDEOS(slug)),
    removeYoutubeChannel: (
        body: IYoutubeChannelRemoveBody,
    ): Promise<IYoutubeChannelRemoveResponse> => hr(routes.POST_REMOVE_YOUTUBE_CHANNEL, body),
    getYoutubeChannelVideos: (
        query: IYoutubeChannelLoadVideosQuery,
    ): Promise<TYoutubeChannelLoadVideosResponse> => hr(routes.GET_YOUTUBE_CHANNEL_SNIPPET(query)),
    getCurrentUserYoutubeChannels: (): Promise<TYoutubeChannelFollowerResponse> =>
        hr(routes.GET_YOUTUBE_CHANNEL_FOLLOWERS),
})

export default youtubeService
