import { handleRequest, handleRequestWithToken } from "../utils/network_util"
import authService from "./auth_service"
import brandStyleService from "./brand_style_service"
import communityService from "./community_service"
import courseService from "./course_service"
import creatorService from "./creator_service"
import liveService from "./live_service"
import paymentService from "./payment_service"
import recordingService from "./recording_service"
import snippetService from "./snippet_service"
import socialPlatformService from "./social_platform_service"
import subscriptionService from "./subscription_service"
import trackingService from "./tracking_service"
import userService from "./user_service"
import youtubeService from "./youtube_service"

export type TApi = ReturnType<typeof registerApiServices>

const registerApiServices = (hr: typeof handleRequest) => ({
    ...authService(hr),
    ...userService(hr),
    ...liveService(hr),
    ...recordingService(hr),
    ...trackingService(hr),
    ...subscriptionService(hr),
    ...paymentService(hr),
    ...communityService(hr),
    ...creatorService(hr),
    ...courseService(hr),
    ...snippetService(hr),
    ...brandStyleService(hr),
    ...socialPlatformService(hr),
    ...youtubeService(hr),
})

const api = (token?: string | null, declareAsServer?: boolean): TApi => {
    const handler = handleRequestWithToken(token!)
    return registerApiServices(handler)
}

export default api
